<template>
  <div class="place-content-center place-items-center mt-20 w-full login">
    <div class="flex place-content-center logo">
      <img src="@/assets/logo.png" alt="netwrk logo" />
    </div>

    <form class="form" @submit.prevent="login">
      <div class="input__row">
        <label class="input">
          <span class="input__label">Email</span>

          <input v-model="email" type="email" name="email" placeholder="mh@cavea.io" class="input__field" required />
        </label>
      </div>

      <div class="input__row">
        <label class="input">
          <span class="input__label">Password</span>

          <input v-model="password" type="password" name="password" class="input__field" required />
        </label>
      </div>

      <Button id="button-manager-login-button" label="Login" kind="primary" type="submit" />

      <p v-if="showLoginError">Wrong email/password</p>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { Button } from "cavea-design-system";

export default {
  name: "Login",

  metaInfo: {
    title: "Login",
  },

  components: {
    Button,
  },

  data() {
    return {
      email: "",
      password: "",
      showLoginError: false,
    };
  },

  computed: {
    ...mapGetters(["getUserInfo"]),
  },

  created() {
    if (this.getUserInfo) {
      this.$router.push("/");
    }
  },

  methods: {
    ...mapActions(["setUserInfo"]),

    /**
     * @summary send authentication request
     */
    async login() {
      const request = await axios
        .post(`${process.env.VUE_APP_API_URL}/users/auth/v2`, {
          email: this.email,
          password: this.password,
        })
        .then((result) => result.data)
        .catch((err) => console.error(err));

      if (request != null) {
        this.setUserInfo(request);
        this.$router.push("/dashboard");
      } else {
        // this.showLoginError = true

        // Popup to slow down people trying to bruteforce
        this.$swal({
          icon: "error",
          title: "Wrong email/password",
          timer: 800,
          showConfirmButton: false,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.login .logo {
  text-align: center;
  color: #fff;

  h1 {
    font-size: 2.5rem;
  }
}

.login .input__label {
  color: #fff;
}

.login .form {
  max-width: 400px;
  width: 100%;
  padding: 2rem;
}

.login .modal .form {
  max-width: 100%;
  padding: 0;
}

.login .actions {
  align-items: center;
  margin: 0;
}

.login .input--radio-list {
  margin-top: 0;
  align-items: center;
  padding: 0;
  margin-right: 1.5rem;

  .input {
    background-color: transparent;
  }

  .input__radio-label {
    color: #fff;
  }

  .input__field:checked {
    & ~ .input__radio-label {
      color: #fff;
    }
  }
}

.input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-inputpositive: 1;
  flex-grow: 1;
  width: 100%;
}

.input__field {
  outline: 0;
  height: 4rem;
  color: #292a38;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 0 1.5rem;
  -webkit-transition: -webkit-box-shadow 0.2s ease-out;
  transition: -webkit-box-shadow 0.2s ease-out;
  transition: box-shadow 0.2s ease-out;
  transition: box-shadow 0.2s ease-out, -webkit-box-shadow 0.2s ease-out;
}

.input__label {
  font-size: 1rem;
  color: #292a38;
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  margin-bottom: 1rem;
}

.input__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -0.25rem 0 0;
  width: 100%;
}

.input__row:not(:last-child) {
  margin-bottom: 1.5rem;
}
</style>
